import React from "react";
import "./App.css";
import Home from "./pages/Home/Home";
import Games from "./pages/Games/Games";
import GameInfo from "./pages/GameInfo/GameInfo";
import Rules from "./pages/Rules/Rules";
import Tips from "./pages/Tips/Tips";
import Support from "./pages/Support/Support";
import MyTeam from "./pages/MyTeam/MyTeam";
import theme from "./theme";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import Footer from "./components/Footer/Footer";
import Subscribe from "./pages/Subscribe/Subscribe";
import store from "./store";
import Auth from "./components/Auth/Auth";
import teamAuth from "./services/teamAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Videos from "./pages/Videos/Videos";

const TeamRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        teamAuth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Auth>
              <Switch>
                <Route exact={true} path="/" component={Home} />
                <Route exact={true} path="/games" component={Games} />
                <Route exact={false} path="/gameinfo" component={GameInfo} />
                <Route exact={true} path="/rules" component={Rules} />
                <Route exact={true} path="/tips" component={Tips} />
                <Route exact={true} path="/support" component={Support} />
                <Route exact={true} path="/videos" component={Videos} />
                <TeamRoute exact={true} path="/myteam" component={MyTeam} />
                <Route exact={true} path="/subscribe" component={Subscribe} />
                <Redirect from="*" to="/" />
              </Switch>
            </Auth>
            <Footer />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
