import teamAuth from "../services/teamAuth";
import jsonwebtoken from "jsonwebtoken";

export const LOGIN_SUCCESS = "@ACCOUNT/LOGIN_SUCCESS";
export const SILENT_LOGIN = "@ACCOUNT/SILENT_LOGIN";
export const LOGOUT = "@ACCOUNT/LOGOUT";

export const sigIn = (login, password) => {
  return async (dispatch) => {
    const token = await teamAuth.sigIn(login, password);
    const team = await teamAuth.getTeam(jsonwebtoken.decode(token)._id);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        team,
      },
    });
  };
};

export const setTeamData = () => {
  return async (dispatch) => {
    const team = await teamAuth.silentSigIn();

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        team,
      },
    });
  };
};

export const signOut = () => {
  return async (dispatch) => {
    await teamAuth.removeToken();
    dispatch({
      type: LOGOUT,
    });
  };
};
