import * as Yup from "yup";

export const validationLogin = Yup.object().shape({
  login: Yup.string()
    .min(4, "muito curto!")
    .max(30, "muito longo!")
    .required("login é obrigatório!"),
  password: Yup.string()
    .min(6, "muito curto!")
    .required("senha é obrigatório!"),
});

export const validationForgot = Yup.object().shape({
  email: Yup.string().email("email inválido!").required("login é obrigatório!"),
});
