import React, { useEffect } from "react";
import Banner from "./Banner/Banner";
import NavBar from "../../components/NavBar/NavBar";
import ButtonsLogin from "../../components/ButtonsLogin/ButtonsLogin";
import { Hidden, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";
import goUp from "../../utils/goUp";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "130px 0",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0",
    },
  },
  gridItem: {
    margin: "35px 0",
  },
  textRight: {
    textAlign: "end",
  },
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
  },
}));

const Home = () => {
  const classes = useStyles();

  useEffect(() => {
    goUp();
  }, []);

  const banner = (
    <Banner>
      <Hidden xsDown>
        <ButtonsLogin />
      </Hidden>
    </Banner>
  );

  const navBar = <NavBar color="#FFF" />;

  return (
    <>
      <Hidden smUp>
        {navBar}
        {banner}
      </Hidden>
      <Hidden xsDown>
        {banner}
        {navBar}
      </Hidden>
      <div className={classes.background}>
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item xs={11} sm={9} md={7} lg={6} className={classes.gridItem}>
            <Typography variant="h1">O que é?</Typography>
            <Typography variant="body1">
              O PET Game Jam é uma competição de desenvolvimento de jogos de
              navegador criada pelo PET Engenharia de Computação da Ufes. Os
              participantes devem se organizar em equipes de até três pessoas
              e programar um jogo de navegador seguindo uma temática fornecida
              no início da competição.
            </Typography>
          </Grid>
          <Grid item xs={11} sm={9} md={7} lg={6} className={classes.gridItem}>
            <Typography variant="h1" className={classes.textRight}>
              Como participar?
            </Typography>
            <Typography variant="body1">
              Os interessados devem se organizar em equipes de no máximo 3
              pessoas e realizar o cadastro no próprio site do evento, durante 
              o período de inscrição. Caso queira ser um lobo solitário, tudo
              bem também, a inscrição é feita da mesma forma. Mas atenção!
              Cada equipe deve ter um nome, então escolham o mais criativo 
              antes de se registrarem!
            </Typography>
          </Grid>
          <Grid item xs={11} sm={9} md={7} lg={6} className={classes.gridItem}>
            <Typography variant="h1">Premiação</Typography>
            <Typography variant="body1">
              Os jogos deverão ser submetidos até a data proposta nas regras.
              Eles serão avaliados pela nossa banca composta por especialistas e
              todos os participantes da melhor equipe serão premiados. O prêmio
              ainda está sendo definido pela organização.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Home;
