import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { apiURL } from "../../../services/urls";
import { DivDropzone } from "./style";

const Dropzone = ({ onFileUploaded, thumbnail, setFileUrl }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const fileUrl = URL.createObjectURL(file);

      setSelectedFileUrl(fileUrl);
      onFileUploaded(file);

      setFileUrl && setFileUrl(fileUrl);
    },
    [onFileUploaded, setFileUrl]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <DivDropzone {...getRootProps()}>
      <input {...getInputProps()} accept="image/*" />

      {selectedFileUrl ? (
        <img src={selectedFileUrl} alt="Preview" />
      ) : thumbnail ? (
        <img src={`${apiURL + thumbnail}`} alt="Preview" />
      ) : (
        <>
          <p>arraste aqui sua thumbnail</p>
        </>
      )}
    </DivDropzone>
  );
};

export default Dropzone;
