import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";
import GameCard from "../../components/GameCard/GameCard";
import api from "../../services/api";
import Loader from "../../components/Loader/Loader";
import goUp from "../../utils/goUp";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
    paddingTop: "150px",
    paddingBottom: "150px",
    minHeight: "100vh",
  },
}));

const Games = () => {
  const classes = useStyles();
  const [games, setGames] = useState();

  useEffect(() => {
    goUp();
  }, []);

  useEffect(() => {
    async function getGames() {
      const gameslist = await api.get("api/game");
      setGames((g) => gameslist.data);
    }
    getGames();
  }, []);

  return (
    <>
      <NavBar color="#000" />
      {!games ? (
        <Loader />
      ) : (
        <Grid
          className={classes.background}
          container
          direction="column"
          alignItems="center"
        >
          {games &&
            games.map((game, index) => {
              if (game.accepted) return <GameCard key={index} game={game} />;
              else return <></>;
            })}
        </Grid>
      )}
    </>
  );
};

export default Games;
