import React, { useState, useEffect, useCallback } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Grid, Typography, Box, Checkbox, Button } from "@material-ui/core";
import InputText from "../../components/InputText/InputText";
import { FiPlus, FiMinus } from "react-icons/fi";
import useStyles from "./styles";
import { Link, useHistory } from "react-router-dom";
import { maskCellphone, maskCep } from "../../utils/masks";
import goUp from "../../utils/goUp";
import api from "../../services/api";
// import { setTeamData } from "../../actions/accountActions";
// import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const wrong = (
  <span style={{ color: "#F44038", fontSize: 14, fontWeight: 300 }}>
    as senhas não conferem!
  </span>
);

const match = (
  <span style={{ color: "#00ac58", fontSize: 14, fontWeight: 300 }}>
    as senhas conferem!
  </span>
);

const short = (
  <span style={{ color: "#F44038", fontSize: 14, fontWeight: 300 }}>
    senha muito curta!
  </span>
);

const Subscribe = () => {
  const classes = useStyles();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [wrongPass, setWrongPass] = useState(false);
  const [rePassword, setRePassword] = useState("");
  const [wrongRePass, setWrongRePass] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [participants, setParticipants] = useState([
    {
      name: "",
      email: "",
      cellphone: "",
      address: "",
      complement: "",
      cep: "",
    },
  ]);
  const history = useHistory();
  // const dispatch = useDispatch();

  useEffect(() => {
    goUp();
  }, []);

  const removeParticipant = () => {
    if (participants.length > 1) {
      let aux = [...participants];
      aux.pop();
      setParticipants([...aux]);
    }
  };

  const addParticipant = () => {
    if (participants.length < 4) {
      setParticipants([
        ...participants,
        {
          name: "",
          email: "",
          cellphone: "",
          address: "",
          complement: "",
          cep: "",
        },
      ]);
    }
  };

  const changeCellphone = (e, index) => {
    let aux = participants;
    aux[index].cellphone = maskCellphone(e.target.value);
    setParticipants([...aux]);
  };

  const changeCep = (e, index) => {
    let aux = participants;
    aux[index].cep = maskCep(e.target.value);
    setParticipants([...aux]);
  };

  const changeParticipant = (e, index) => {
    let aux = participants;
    aux[index][e.target.name] = e.target.value;
    setParticipants([...aux]);
  };

  const checkWrongPass = useCallback(() => {
    if (password.length < 6 && password.length > 0) setWrongPass(true);
    else setWrongPass(false);
    if (password !== rePassword) setWrongRePass(true);
    else setWrongRePass(false);
  }, [password, rePassword]);

  useEffect(() => {
    checkWrongPass();
  }, [password, rePassword, checkWrongPass]);

  const renderPasswordStatus = () => {
    if (!wrongRePass && password.length === 0) {
      return null;
    }
    if (wrongRePass && rePassword.length > 0) return wrong;
    if (!wrongRePass) return match;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const responseTeam = await api.post("/api/team", {
        login,
        teamName,
        password,
      });

      window.localStorage.setItem(
        "LOGINGAMEJAM_TOKEN",
        responseTeam.data.token
      );

      for (let i = 0; i < participants.length; i++) {
        try {
          const responseParticipant = await api.post("/api/participant", {
            name: participants[i].name,
            email: participants[i].email,
            phone: participants[i].cellphone,
            address: participants[i].address,
            complement: participants[i].complement,
            cep: participants[i].cep,
          });

          try {
            await api.patch(
              `/api/team/addParticipant/${responseTeam.data.team}`,
              { participant: responseParticipant.data.participant },
              {
                headers: {
                  Authorization: "Bearer " + responseTeam.data.token,
                },
              }
            );
          } catch (err) {
            await api.delete(`/api/team/${responseTeam.data.team}`, {
              headers: {
                Authorization: "Bearer " + responseTeam.data.token,
              },
            });
            toast.error(
              <div>
                <strong>Participante:&nbsp;{i + 1}</strong>
                <br />
                {err.response.data}
              </div>
            );
            return null;
          }
        } catch (err) {
          await api.delete(`/api/team/${responseTeam.data.team}`, {
            headers: {
              Authorization: "Bearer " + responseTeam.data.token,
            },
          });
          toast.error(
            <div>
              <strong>Participante:&nbsp;{i + 1}</strong>
              <br />
              {err.response.data}
            </div>
          );
          return null;
        }
      }

      // dispatch(setTeamData());
      toast.success("Cadastro de time realizado com sucesso!");
      history.push("/");
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  return (
    <>
      <NavBar color="#000" />
      <div className={classes.background}>
        {Date.now() < new Date("02/05/2023") || // TEM QUE MUDAR AQUI QUANDO FOR MEXER NO CÓDIGO
        Date.now() > new Date("03/17/2023") ? (
          <Grid container justify="center" style={{ height: "100vh" }}>
            <Grid item xs={11} sm={9} md={7} lg={6}>
              <Typography
                variant="h1"
                style={{ paddingTop: 150, textJustify: "none" }}
              >
                As inscrições estão fechadas...
              </Typography>
              <Typography variant="body1">
                Sentimos muito caso não tenha conseguido se inscrever à tempo
                :(. A partir do dia 13/03/2023 as submissões de jogos começarão
                a chegar, então dê uma passadinha no site para ver as criações
                dos participantes. Não se esqueça de nos seguir no Instagram
                para participar da votação de jogo vencedor.
              </Typography>
              <Typography variant="body1">Divirta-se!!!</Typography>
            </Grid>
          </Grid>
        ) : (
          <form style={{ padding: "100px 0" }} onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={11} sm={9} md={7} lg={6}>
                <Typography
                  variant="h2"
                  style={{ marginBottom: 30, textAlign: "initial" }}
                >
                  Informações da equipe
                </Typography>
                <Grid container direction="column" item>
                  <Grid item className={classes.gridInputTeam}>
                    <InputText
                      name="login"
                      type="text"
                      placeholder="login*"
                      value={login}
                      onChange={(e) => setLogin(e.target.value)}
                      minLength="4"
                      required
                    />
                  </Grid>
                  <Grid item className={classes.gridInputTeam}>
                    <InputText
                      name="password"
                      type="password"
                      placeholder="senha*"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={wrongPass}
                      required
                    />
                    <Grid item>{wrongPass && short}</Grid>
                  </Grid>
                  <Grid item className={classes.gridInputTeam}>
                    <InputText
                      name="repeatPassword"
                      type="password"
                      placeholder="confirmar senha*"
                      value={rePassword}
                      onChange={(e) => setRePassword(e.target.value)}
                      error={rePassword.length === 0 ? false : wrongRePass}
                      required
                    />
                    <Grid item>{renderPasswordStatus()}</Grid>
                  </Grid>
                  <Grid item className={classes.gridInputTeam}>
                    <InputText
                      name="teamName"
                      type="text"
                      placeholder="nome da equipe"
                      value={teamName}
                      onChange={(e) => setTeamName(e.target.value)}
                      required
                    />
                    <span className={classes.observationNameText}>
                      tá sozinho na equipe? deixa o seu nome :)
                    </span>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ margin: "43px 0 30px 0" }}
                  direction="row"
                  item
                >
                  <Grid item>
                    <Typography variant="h2">Participantes&nbsp;</Typography>
                  </Grid>
                  <Grid item>
                    <span className={classes.observationParticipantText}>
                      (1 - 3 pessoas)
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid container direction="row" justify="center" item xs={11}>
                {participants.map((participant, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    style={{ margin: 10 }}
                    key={index}
                  >
                    <Box className={classes.boxParticipant}>
                      <Grid container item justify="center" direction="column">
                        <Grid item className={classes.gridInputParticipant}>
                          <InputText
                            name="name"
                            type="text"
                            placeholder="nome*"
                            value={participant.name}
                            onChange={(e) => changeParticipant(e, index)}
                            required
                          />
                        </Grid>
                        <Grid item className={classes.gridInputParticipant}>
                          <InputText
                            name="email"
                            type="email"
                            placeholder="email*"
                            value={participant.email}
                            onChange={(e) => changeParticipant(e, index)}
                            required
                          />
                        </Grid>
                        <Grid item className={classes.gridInputParticipant}>
                          <InputText
                            name={`cellphone${index}`}
                            type="text"
                            placeholder="telefone"
                            value={participant.cellphone}
                            onChange={(e) => changeCellphone(e, index)}
                            maxLength="15"
                            minLength="14"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        justify="center"
                        alignContent="center"
                        direction="column"
                        className={classes.gridInputAddress}
                      >
                        <Grid
                          item
                          className={classes.gridInputParticipant}
                          style={{ width: "100%" }}
                        >
                          <InputText
                            name="address"
                            type="text"
                            placeholder="endereço*"
                            value={participant.address}
                            onChange={(e) => changeParticipant(e, index)}
                            required
                          />
                        </Grid>
                        <Grid item className={classes.gridInputParticipant}>
                          <InputText
                            name="complement"
                            type="text"
                            placeholder="complemento"
                            value={participant.complement}
                            onChange={(e) => changeParticipant(e, index)}
                            required
                          />
                        </Grid>
                        <Grid item className={classes.gridInputParticipant}>
                          <InputText
                            name={`cep${index}`}
                            type="text"
                            placeholder="CEP*"
                            value={participant.cep}
                            onChange={(e) => changeCep(e, index)}
                            minLength="9"
                            maxLength="9"
                            required
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
              >
                <Grid
                  item
                  xs={11}
                  sm={9}
                  md={7}
                  lg={6}
                  style={{ textAlign: "center" }}
                >
                  {participants.length > 1 && (
                    <FiMinus
                      className={classes.icons}
                      onClick={removeParticipant}
                    />
                  )}
                  {participants.length < 3 && (
                    <FiPlus
                      className={classes.icons}
                      onClick={addParticipant}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={11}
                  sm={9}
                  md={7}
                  lg={6}
                  style={{ textAlign: "center" }}
                >
                  <div className={classes.divInputCheckbox}>
                    <Checkbox
                      name="agree"
                      id="agree"
                      color="primary"
                      required
                    />
                    <label htmlFor="agree" className={classes.labelAgree}>
                      li e concordo com os{" "}
                      <Link
                        to="/rules"
                        target="_blank"
                        className={classes.link}
                      >
                        {/* <a
                        href="https://gamejam.pet.inf.ufes.br/rules"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.competitionTerms}
                      > */}
                        termos da competição
                        {/* </a> */}
                      </Link>
                    </label>
                  </div>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  item
                  xs={11}
                  sm={9}
                  md={7}
                  lg={6}
                  style={{ margin: "30px 0 0 0" }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      className={`${classes.button} ${classes.cancelButton}`}
                      type="reset"
                    >
                      cancelar :(
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={`${classes.button} ${classes.subscribeButton}`}
                      type="submit"
                      disabled={wrongPass || wrongRePass}
                    >
                      inscrever!
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </>
  );
};

export default Subscribe;
