import React from "react";
import { Button, Fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import InputText from "../InputText/InputText";
import { useDispatch } from "react-redux";
import { sigIn } from "../../actions/accountActions";
import { validationLogin } from "../../utils/validation";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  gridItemInput: {
    [theme.breakpoints.up("sm")]: {
      margin: "0px 27px",
    },
  },
  gridItemButton: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 16,
  },
  colorfulText: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 300,
    transition: "color .25s",

    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  button: {
    borderRadius: 53,
    fontSize: 18,
    fontWeight: 500,
    textTransform: "lowercase",
    margin: "0px 10px",
    boxShadow: "none",
    color: "#FFF",
  },
  cancelButton: {
    backgroundColor: theme.palette.disabled.main,
    "&:hover": {
      backgroundColor: theme.palette.disabled.dark,
    },
  },
  formError: {
    padding: "5px 0 0 15px",
    color: "#F44038",
  },
}));

const LoginForm = ({ handleClose, setPage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const { login, password } = values;
    try {
      await dispatch(sigIn(login, password));
      toast.success("Login realizado com sucesso!");
      handleClose();
    } catch (err) {
      toast.error("Erro ao realizar login, confira suas credenciais.");
    }
  };

  return (
    <Fade in={true}>
      <div>
        <div style={{ marginBottom: 27 }}>
          <Typography variant="h2">Login</Typography>
        </div>
        <Formik
          validationSchema={validationLogin}
          onSubmit={handleSubmit}
          initialValues={{ login: "", password: "" }}
        >
          {({ values, errors, isValid, touched }) => (
            <Form>
              <Grid container direction="column">
                <Grid
                  item
                  style={{ paddingBottom: 22 }}
                  className={classes.gridItemInput}
                >
                  <Field name="login">
                    {({ field, meta }) => (
                      <>
                        <InputText
                          type="text"
                          {...field}
                          placeholder="login"
                          contained
                        />
                        {meta.touched && meta.error && (
                          <div className={classes.formError}>{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid
                  item
                  style={{ paddingBottom: 17 }}
                  className={classes.gridItemInput}
                >
                  <Field name="password">
                    {({ field, meta }) => (
                      <>
                        <InputText
                          type="password"
                          {...field}
                          placeholder="senha"
                          contained
                        />
                        {meta.touched && meta.error && (
                          <div className={classes.formError}>{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                  <div
                    style={{ margin: "5px 15px" }}
                    onClick={() => setPage(2)}
                  >
                    <span className={classes.colorfulText}>
                      esqueci minha senha :(
                    </span>
                  </div>
                </Grid>
                <Grid item className={classes.gridItemButton}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    type="submit"
                    disabled={!isValid}
                  >
                    entrar
                  </Button>
                  <Button
                    type="reset"
                    onClick={handleClose}
                    variant="contained"
                    className={`${classes.button} ${classes.cancelButton}`}
                  >
                    cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Grid container justify="center" item>
          Não está inscrito?&nbsp;
          <Link to="subscribe" style={{ textDecoration: "none" }}>
            <span className={classes.colorfulText}> Se inscreva aqui</span>
          </Link>
        </Grid>
      </div>
    </Fade>
  );
};

export default LoginForm;
