import { LOGIN_SUCCESS, SILENT_LOGIN, LOGOUT } from "../actions/accountActions";

const INITIAL_STATE = {
  team: null,
};

const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, team: action.payload.team };

    case SILENT_LOGIN:
      return { ...state, team: action.payload.team };

    case LOGOUT:
      return { ...state, team: null };

    default:
      return state;
  }
};

export default accountReducer;
