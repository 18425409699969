import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PacmanLoader from "react-spinners/PacmanLoader";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PacmanLoader size={50} color={"#C641FF"} loading={true} />
    </div>
  );
};

export default Loader;
