import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
  },
  gridInputTeam: {
    margin: "10px 0",
    display: "flex",
    alignItems: "flex-end",
  },
  observationNameText: {
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.primary.main,
    marginLeft: "20px",
    textAlign: "initial",
  },
  changePass: {
    cursor: "pointer",
    transition: ".25s color",
    textDecoration: "underline",

    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  observationParticipantText: {
    fontWeight: 400,
    fontSize: 18,
    [theme.breakpoints.up(393)]: {
      lineHeight: 2.6,
    },
  },
  boxParticipant: {
    padding: "10% 7%",
    border: "3px solid #000",
    width: "100%",
    borderRadius: 4,
  },
  gridInputParticipant: {
    margin: "7px 0",
  },
  gridInputAddress: {
    marginTop: 38,
    width: "100%",
  },
  icons: {
    fontSize: 50,
    cursor: "pointer",
    margin: 10,
  },
  labelAgree: {
    fontWeight: 300,
    fontSize: 20,

    [theme.breakpoints.down(475)]: {
      transform: "translateY(8px)",
      textAlign: "left",
    },
  },
  competitionTerms: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  divInputCheckbox: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(475)]: {
      alignItems: "flex-start",
    },
  },
  inputCheckbox: {
    marginRight: 10,
  },
  button: {
    borderRadius: 53,
    width: 150,
    fontSize: 18,
    fontWeight: 100,
    textTransform: "lowercase",
    margin: "10px 10px",
    boxShadow: "none",
    color: "#FFF",
  },
  uploadButton: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
    borderRadius: "20px",
    color: "#FFF",
  },
  cancelButton: {
    color: "#797979",
    backgroundColor: theme.palette.disabled.light,
    "&:hover": {
      backgroundColor: theme.palette.disabled.main,
    },
  },
  subscribeButton: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  inputParticipantes: {
    borderRadius: 0,
    border: 0,
    height: "30px",
    borderBottom: `2px solid #000`,
    marginBottom: "7px",
    marginLeft: "10px",
    fontSize: 12,
  },
  tipIcon: {
    color: "#797979",
    cursor: "pointer",
    transition: "0.25s color",

    "&:hover": {
      color: "#000",
    },
  },
  contentPop: {
    color: "#797979",
    padding: 14,
    width: 227,
  },
  linkPop: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  linkFont: {
    textDecoration: "none",
    color: "#9e00ff",
    transition: "color 0.25s",

    "&:hover": {
      color: "#7600D8",
    },
  },
}));

export default useStyles;
