import api from "./api";

class Team {
  sigIn = async (login, password) => {
    try {
      const responseLogin = await api.get("/api/login/", {
        auth: {
          username: login,
          password: password,
        },
      });

      window.localStorage.setItem(
        "LOGINGAMEJAM_TOKEN",
        responseLogin.data.token
      );
      return responseLogin.data.token;
    } catch (err) {
      return err.response;
    }
  };

  silentSigIn = async () => {
    if (!this.isAuthenticated()) return null;

    try {
      const responseLogin = await api.get("/api/login/silentLogin/", {
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      });
      return responseLogin.data.team;
    } catch (err) {
      this.removeToken();
      return null;
    }
  };

  getTeam = async (idTeam) => {
    try {
      const responseUser = await api.get(`/api/team/${idTeam}`, {
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      });
      return responseUser.data.team;
    } catch (err) {
      return err.response;
    }
  };

  removeToken = () => localStorage.removeItem("LOGINGAMEJAM_TOKEN");

  getToken = () => localStorage.getItem("LOGINGAMEJAM_TOKEN");

  isAuthenticated = () => !!this.getToken();
}

const teamAuth = new Team();

export default teamAuth;
