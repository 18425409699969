import styled from "styled-components";

const error = `
  color: #F44038;
  
  ::placeholder {
    color: red;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: red;
  }
  
  ::-ms-input-placeholder {
    color: red;
  }
`;

const contained = (props) => `
  background-color: #eaeaea;
  border: ${props.error ? "2px #F44038 solid" : "none"};

  &:focus {
    outline: none;
    box-shadow: 0 0 1px 1px ${props.error ? "#F44038" : "#e1e1e1"};
  }
`;

const outlined = (props) => `
  border: ${props.error ? "2px #F44038 solid" : "2px #000 solid"};

  &:focus {
    outline: none;
    box-shadow: 0 0 1px 1px ${props.error ? "#F44038" : "#000"};
  }
`;

const InputText = styled.textarea`
  width: 100%;
  height: 200px;
  font-size: 18px;
  border-radius: 33.5px;
  padding: 10px 15px;
  font-family: ufes_sans;
  font-weight: 200;
  ${(props) => (props.contained ? contained(props) : outlined(props))}

  ${(props) => (props.error ? error : "")}
`;

export default InputText;
