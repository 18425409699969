import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: (props) => (props.color === "#000" ? "#FFF" : "#000"),
  },
  linkActive: {
    color: theme.palette.primary.main,
  },
  linkText: {
    textDecoration: "none",
    transition: "color 0.25s",

    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}));

const NavLinks = (props) => {
  const classes = useStyles(props);
  const account = useSelector((state) => state.account.team);

  useEffect(() => {
    let path = window.location.pathname.split("/")[1] || "home";
    if (path === "gameinfo") path = "games";
    let link = document.querySelector(`#${path}`);
    if (link) link.classList.add(classes.linkActive);
  }, [classes, account]);

  return (
    <>
      <Link to="/" className={classes.link}>
        <Typography variant="h6" id="home" className={classes.linkText}>
          inicio
        </Typography>
      </Link>
      <Link to="/games" className={classes.link}>
        <Typography variant="h6" id="games" className={classes.linkText}>
          jogos
        </Typography>
      </Link>
      <Link to="/rules" className={classes.link}>
        <Typography variant="h6" id="rules" className={classes.linkText}>
          regras
        </Typography>
      </Link>
      <Link to="/tips" className={classes.link}>
        <Typography variant="h6" id="tips" className={classes.linkText}>
          dicas
        </Typography>
      </Link>
      <Link to="/support" className={classes.link}>
        <Typography variant="h6" id="support" className={classes.linkText}>
          patrocinadores
        </Typography>
      </Link>
      <Link to="/videos" className={classes.link}>
        <Typography variant="h6" id="videos" className={classes.linkText}>
          videos
        </Typography>
      </Link>
      {account && (
        <Link to="/myteam" className={classes.link}>
          <Typography variant="h6" id="myteam" className={classes.linkText}>
            minha equipe
          </Typography>
        </Link>
      )}
      {props.children}
    </>
  );
};

export default NavLinks;
