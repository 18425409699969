import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { apiURL } from "../../services/urls";

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "row",
    justifyItems: "center",
    alignItems: "center",
    marginBottom: "50px",
    [theme.breakpoints.down(1292)]: {
      flexDirection: "column",
    },
  },
  gameImage: {
    backgroundColor: "rgb(25,46,157)",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    borderRadius: "3px",
    width: "348px",
    minWidth: "300px",
    height: "234px",
    minHeight: "200px",
    elevation: "0px",
    borderWidth: "0px",
    outline: "none",
    transition: "opacity 0.25s",
    "&:hover": {
      opacity: "0.8",
      cursor: "pointer",
    },
    [theme.breakpoints.down(1292)]: {
      marginRight: "0px",
    },
  },
  evaluation: {
    flexDirection: "row",
    alignItems: "center",
  },
  gameInfo: {
    minWidth: "300px",
    width: "348px",
    marginLeft: "0px",
    [theme.breakpoints.up(1292)]: {
      paddingLeft: 46,
    },
  },
  gameName: {
    fontSize: "20px",
    lineHeight: "26px",
    marginRight: "13px",
  },
  teamName: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000",
  },
  gameDescription: {
    fontSize: "16px",
    lineHeight: "21px",
    color: "#666",
  },
}));

const GameCard = (props) => {
  const history = useHistory();
  const classes = useStyles(props);

  return (
    <Grid
      className={classes.container}
      container
      direction="row"
      justify="center"
      item
      xs={11}
      md={8}
      lg={6}
    >
      <Grid
        className={classes.gameImage}
        onClick={() => {
          history.push(`/gameinfo/${props.game._id}`);
        }}
        item
        sm={4}
        xs={11}
        style={{
          backgroundImage: `url(${apiURL}${props.game.thumbnail})`,
        }}
      ></Grid>
      <Grid className={classes.gameInfo} item sm={4} xs={11}>
        <Grid className={classes.evaluation} container direction="row">
          <Typography className={classes.gameName} variant={"body1"}>
            <b>{props.game.name}</b>
          </Typography>
        </Grid>
        <Typography className={classes.teamName} variant={"body2"}>
          <b>por {props.game.team.teamName}</b>
        </Typography>
        <Typography className={classes.gameDescription} variant={"body2"}>
          {" "}
          {props.game.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GameCard;
