import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../../assets/images/banner.png";

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundImage: `url(${image})`,
    height: "40vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "left",
  },
  textBanner: {
    textShadow: "0.1em 0.1em 0.2em black",
    fontSize: 70,
    color: "#fff",
    fontFamily: "'Press Start 2P', cursive",
    textAlign: "center",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 50,
    },
  },
}));

const Banner = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.backgroundImage}>
      {children}
    </div>
  );
};

export default Banner;
