import React, { useCallback, useEffect } from "react";
import team from "../../services/teamAuth";
import { setTeamData } from "../../actions/accountActions";
import { useDispatch } from "react-redux";

const Auth = ({ children }) => {
  const dispatch = useDispatch();

  const initAuth = useCallback(async () => {
    if (team.isAuthenticated()) {
      await dispatch(setTeamData());
    }
  }, [dispatch]);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  return <>{children}</>;
};

export default Auth;
