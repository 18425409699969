import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: "ufes_sans",
    h1: {
      fontWeight: "300",
      fontSize: "2.5rem",
      color: "#192E9D",
    },
    h2: {
      fontWeight: "300",
      fontSize: "2.5rem",
      color: "#000",
    },
    h3: {
      fontWeight: "500",
      fontSize: "2.0rem",
      color: "#000",
    },
    h4: {
      fontWeight: "600",
      fontSize: "1.5rem",
      color: "#192E9D",
    },
    h5: {
      fontWeight: "450",
      fontSize: "2.0rem",
      color: "#000",
    },
    h6: {
      textDecoration: "none",
      fontWeight: 300,
      fontSize: 18,
      padding: 8,
    },
    body1: {
      fontWeight: "normal",
      fontSize: 18,
      color: "#000",
    },
  },
  palette: {
    primary: {
      light: "#3E76E2",
      main: "#192E9D",
      dark: "#101C8C",
    },
    secondary: {
      light: "#FFA8A8",
      main: "#FF9494",
      dark: "#E86363",
    },
    success: {
      light: "#5fffa6",
      main: "#1ED67E",
      dark: "#00ac58",
    },
    disabled: {
      light: "#E4E4E4",
      main: "#C4C4C4",
      dark: "#A4A4A4",
    },
  },
});

export default theme;
