import React, { useState } from "react";
import { DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: "5% !important",
  },
}));

const ModalLogin = ({ handleClose }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        {page === 1 ? (
          <LoginForm handleClose={handleClose} setPage={setPage} />
        ) : (
          <ForgotPassword handleClose={handleClose} setPage={setPage} />
        )}
      </DialogContent>
    </>
  );
};

export default ModalLogin;
