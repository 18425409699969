import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import logoPet from "../../assets/images/logo_pet_branca.svg";
import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";
import { SiGmail } from "react-icons/si";

const useStyles = makeStyles((theme) => ({
  containerFooter: {
    backgroundColor: "#393939",
    paddingTop: 44,
  },
  socialMedia: {
    fontSize: 16,
    color: "#FFF",
    fontWeight: 300,
    textDecoration: "none",
  },
  gridJustify: {
    justifyContent: "space-between",
    [theme.breakpoints.down(413)]: {
      justifyContent: "center",
    },
  },
  copyright: {
    fontSize: 12,
    color: "#FFF",
    fontWeight: 300,
    textAlign: "center",
  },
  icons: {
    marginRight: 8,
    fontSize: 22,
    color: "#FFF",
    transform: "translateY(5px)",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Container className={classes.containerFooter} maxWidth={false}>
      <Grid
        container
        className={classes.gridJustify}
        alignItems="center"
        spacing={3}
      >
        <Grid item sm={3}>
          <a
            target="_blank"
            href="https://pet.inf.ufes.br"
            rel="noopener noreferrer"
          >
            <img src={logoPet} alt="PET Engenharia de Computação" />
          </a>
        </Grid>
        <Grid item sm={5} md={3}>
          <Grid container direction="column" justify="center">
            <Grid item>
              <SiGmail className={classes.icons} />
              <a
                className={classes.socialMedia}
                target="_blank"
                href="https://pet.inf.ufes.br/contato"
                rel="noopener noreferrer"
              >
                petengcomp@inf.ufes.br
              </a>
            </Grid>
            <Grid item>
              <AiOutlineInstagram className={classes.icons} />
              <a
                className={classes.socialMedia}
                target="_blank"
                href="https://www.instagram.com/petengcomp/?hl=pt-br"
                rel="noopener noreferrer"
              >
                @petengcomp
              </a>
            </Grid>
            <Grid item>
              <AiFillYoutube className={classes.icons} />
              <a
                className={classes.socialMedia}
                target="_blank"
                href="https://www.youtube.com/channel/UCE7rLHCeJukvySeqrz_mxAg"
                rel="noopener noreferrer"
              >
                PET Eng Comp Ufes
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.copyright}>
          © {new Date().getFullYear()} Copyright: PET Engenharia de Computação - Ufes
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
