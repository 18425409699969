import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Typography, Dialog, Slide } from "@material-ui/core";
import ModalLogin from "../ModalLogin/ModalLogin";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { signOut } from "../../actions/accountActions";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 53,
    fontSize: 18,
    textTransform: "lowercase",
    marginLeft: 26,
  },
  divButtons: {
    justifyContent: "flex-end",
    padding: "42px 5% 42px 0",
    display: "flex",
    position: "sticky",
    top: 0,
  },
  login: {
    textAlign: "center",
    color: (props) => (props.color === "#000" ? "#FFF" : "#000"),
    cursor: "pointer",
    transition: "background-color .25s",

    "&:hover": {
      backgroundColor: (props) =>
        props.color === "#000" ? "#444" : theme.palette.secondary.dark,
    },
  },
  register: {
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    color: "#FFF",
    cursor: "pointer",
    transition: "background-color .25s",
    textDecoration: "none",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dialogContainer: {
    marginTop: "10%",
    alignItems: "baseline",
  },
  dialogPaper: {
    borderRadius: 7,
    width: 500,
    [theme.breakpoints.down(320)]: {
      margin: "32px 0px",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonsLogin = (props) => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const account = useSelector((state) => state.account.team);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    dispatch(signOut());
    history.push("/");
  };

  return (
    <>
      <Hidden xsDown>
        <div className={classes.divButtons}>
          {account ? (
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              onClick={logout}
            >
              sair
            </Button>
          ) : (
            <>
              <Link to="subscribe" style={{ textDecoration: "none" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  inscreva-se!
                </Button>
              </Link>
              <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                onClick={handleClickOpen}
              >
                login
              </Button>
            </>
          )}
        </div>
      </Hidden>
      <Hidden smUp>
        {account ? (
          <span className={classes.login} onClick={logout}>
            <Typography variant="h6">sair</Typography>
          </span>
        ) : (
          <>
            <span className={classes.login} onClick={handleClickOpen}>
              <Typography variant="h6">login</Typography>
            </span>
            <Link to="subscribe" className={classes.register}>
              <Typography variant="h6">inscreva-se!</Typography>
            </Link>
          </>
        )}
      </Hidden>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{
          paper: classes.dialogPaper,
          container: classes.dialogContainer,
        }}
      >
        <ModalLogin handleClose={handleClose} />
      </Dialog>
    </>
  );
};

export default ButtonsLogin;
