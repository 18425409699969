import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Grid, Typography, Button, Popover } from "@material-ui/core";
import InputText from "../../components/InputText/InputText";
import InputTextLarge from "../../components/InputTextLarge/InputTextLarge";
import useStyles from "./styles";
import { FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setTeamData } from "../../actions/accountActions";
import { maskCellphone, maskCep } from "../../utils/masks";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Dropzone from "./Dropzone/Dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";

import goUp from "../../utils/goUp";

import api from "../../services/api";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const MyTeam = () => {
  const classes = useStyles();
  const dados = useSelector((state) => state.account.team);

  const [teamName, setTeamName] = useState("");
  const [login, setLogin] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [participantes, setParticipantes] = useState([]);
  const [gameName, setGameName] = useState("");
  const [description, setDescription] = useState("");
  const [techs, setTechs] = useState("");
  const [file, setFile] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [thumbnail, setThumbnail] = useState();
  const dispatch = useDispatch();

  const [teamNameEdit, setTeamNameEdit] = useState(true);
  const [passwordEdit, setPasswordEdit] = useState(true);
  const [participantesEdit, setParticipantesEdit] = useState(null);

  const [anchorPop, setAnchorPop] = useState(null);

  const open = Boolean(anchorPop);
  const id = open ? "popover" : undefined;

  useEffect(() => {
    goUp();
  }, []);

  useEffect(() => {
    if (dados) {
      setTeamName(dados.teamName);
      setParticipantes([...dados.participants]);
      if (dados.game) {
        // console.log(dados.game);
        setGameName(dados.game.name);
        setDescription(dados.game.description);
        setTechs(dados.game.techs);
      }
    }
  }, [dados]);

  const changeParticipant = (e, index) => {
    let aux = participantes;
    aux[index][e.target.name] = e.target.value;
    setParticipantes([...aux]);
  };

  // Atualiza os dados do time
  const handleSubmitUpdateTeam = async (e) => {
    e.preventDefault();
    const token = window.localStorage.getItem("LOGINGAMEJAM_TOKEN");

    try {
      await api.put(
        `api/team/${dados?._id}`,
        {
          teamName,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      handleSubmitUpdateParticipants(e);
      toast.success("Salvo com sucesso!");
    } catch (err) {
      toast.error("Erro ao salvar, tente novamente.");
      console.log(toast.error(err.response.data));
    }

    if (newPassword !== "") {
      try {
        await api.patch(
          `api/login/changePassword`,
          {
            login: login,
            password: oldPassword,
            newPassword: newPassword,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        handleSubmitUpdateParticipants(e);
        toast.success("Senha alterada com sucesso!");
      } catch (err) {
        toast.error("Falha ao alterar senha, tente novamente.");
        console.log(toast.error(err.response.data));
      }
    }
  };

  // Atualiza os dados dos participantes
  const handleSubmitUpdateParticipants = async (e) => {
    e.preventDefault();
    const token = window.localStorage.getItem("LOGINGAMEJAM_TOKEN");

    for (let i = 0; i < participantes.length; i++) {
      try {
        await api.put(
          `api/participant/${participantes[i]._id}`,
          {
            name: participantes[i].name,
            email: participantes[i].email,
            phone: participantes[i].phone,
            address: participantes[i].address,
            complement: participantes[i].complement,
            cep: participantes[i].cep,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        // toast.success(`Participante ${i} atualizado com sucesso`);
      } catch (err) {
        // toast.error(`Participante ${i} não atualizado`);
        console.log(toast.error(err.response.data));
      }
    }
  };
  // Enviar o jogo do time
  const handleSubmitAddGame = async (e) => {
    e.preventDefault();
    const token = window.localStorage.getItem("LOGINGAMEJAM_TOKEN");

    try {
      const response = await api.post(
        "api/game/add",
        {
          name: gameName,
          teamId: dados?._id,
          description: description,
          techs: techs,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      // toast.success("Jogo enviado");

      try {
        await api.patch(
          `api/team/setGame/${dados?._id}`,
          {
            game: response?.data.game,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        // toast.success("Jogo vinculado ao time");

        // enviar o arquivo do jogo
        const formData = new FormData();
        formData.append("files", file);
        formData.append("thumbnail", thumbnail);

        try {
          // console.log(`${response?.data?.game}`)
          await api.post(`api/game/upload/${response?.data?.game}`, formData, {
            headers: {
              Authorization: "Bearer " + token,
            },
            onUploadProgress: (e) => {
              const progress = parseInt(Math.round((e.loaded * 100) / e.total));

              setUploadProgress(progress);
            },
          });
          toast.success("Arquivo do jogo enviado");

          await dispatch(setTeamData());
        } catch (err) {
          toast.error("Erro ao enviar arquivo do jogo");
          console.log(err);
        }
      } catch (err) {
        toast.error("Erro ao vincular o jogo");
      }
    } catch (err) {
      toast.error("Erro ao instanciar o jogo, tente novamente.");
    }
  };

  function EditParticipante(item) {
    if (participantesEdit === item._id) {
      setParticipantesEdit(null);
      return;
    }
    setParticipantesEdit(item._id);
  }

  const changeCellphone = (e, index) => {
    e.target.value = maskCellphone(e.target.value);
    changeParticipant(e, index);
  };

  const changeCep = (e, index) => {
    e.target.value = maskCep(e.target.value);
    changeParticipant(e, index);
  };

  return (
    <>
      <NavBar color="#000" />
      {!dados ? (
        <Loader />
      ) : dados.participants.length < 1 ? (
        //Caso o time não possua nenhum participante nele
        <div className={classes.background}>
          <Grid container justify="center" style={{ height: "100vh" }}>
            <Grid item xs={11} sm={9} md={7} lg={6}>
              <Typography
                variant="h1"
                style={{ paddingTop: 150, textJustify: "none" }}
              >
                Opsss, tivemos um problema...
              </Typography>
              <Typography variant="body1">
                O seu time não possui nenhum participante cadastrado em nosso
                sistema. Entre em contato com o PET Eng Comp para que possamos
                tentar resolver esse problema.
              </Typography>
              <Typography variant="body1">
                Acesse: &nbsp;
                <a
                  href="https://pet.inf.ufes.br/contato"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  pet.inf.ufes.br/contato
                </a>
              </Typography>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.background}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ padding: "100px 0" }}
          >
            <Grid
              item
              xs={11}
              sm={8}
              md={6}
              lg={4}
              xl={3}
              style={{ width: "100%" }}
            >
              <form onSubmit={handleSubmitUpdateTeam}>
                <Typography
                  variant="h1"
                  style={{ marginBottom: 30, textAlign: "initial" }}
                >
                  Equipe {dados?.teamName}
                </Typography>
                <br></br>

                <span className={classes.observationNameText}>
                  nome da equipe
                </span>
                <Grid item className={classes.gridInputTeam}>
                  <InputText
                    name="teamName"
                    type="text"
                    placeholder="nome da equipe"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    required
                    disabled={teamNameEdit}
                  />
                  <Button onClick={() => setTeamNameEdit(!teamNameEdit)}>
                    <FaEdit
                      color="dark"
                      size={30}
                      style={{ paddingLeft: 10 }}
                    />
                  </Button>
                </Grid>

                <span
                  className={
                    classes.observationNameText + " " + classes.changePass
                  }
                  onClick={() => setPasswordEdit(!passwordEdit)}
                >
                  alterar senha
                </span>
                <Grid item className={classes.gridInputTeam}>
                  {!passwordEdit && (
                    <div style={{ flexDirection: "column" }}>
                      <InputText
                        name="login"
                        type="text"
                        placeholder="Login do time"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        required
                        disabled={passwordEdit}
                        style={{ marginTop: "15px" }}
                      />

                      <InputText
                        name="oldPassword"
                        type="password"
                        placeholder="Senha antiga"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                        style={{ marginTop: "15px" }}
                        disabled={passwordEdit}
                      />

                      <InputText
                        name="newPassword"
                        type="password"
                        placeholder="Nova senha"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        style={{ marginTop: "15px" }}
                        disabled={passwordEdit}
                      />
                    </div>
                  )}
                </Grid>
                <br />
                <Grid container direction="column" item>
                  <Grid item>
                    <Typography variant="h5">Participantes</Typography>
                  </Grid>

                  {dados?.participants.map((item, index) => (
                    <Grid
                      item
                      className={classes.gridInputTeam}
                      key={index}
                      extradata={participantesEdit}
                    >
                      {participantesEdit === item?._id ? (
                        <div style={{ border: `2px solid #000` }}>
                          <input
                            className={classes.inputParticipantes}
                            name="name"
                            type="text"
                            placeholder="Nome"
                            value={item?.name}
                            onChange={(e) => changeParticipant(e, index)}
                            required
                          />
                          <br />
                          <input
                            className={classes.inputParticipantes}
                            name="email"
                            type="text"
                            placeholder="email"
                            value={item?.email}
                            onChange={(e) => changeParticipant(e, index)}
                            required
                          />
                          <input
                            className={classes.inputParticipantes}
                            name={`cellphone${index}`}
                            type="text"
                            placeholder="telefone"
                            maxLength="15"
                            minLength="14"
                            value={item?.phone}
                            onChange={(e) => changeCellphone(e, index)}
                          />
                          <input
                            className={classes.inputParticipantes}
                            style={{ marginTop: "15px" }}
                            name="address"
                            type="text"
                            placeholder="Endereço"
                            value={item?.address}
                            onChange={(e) => changeParticipant(e, index)}
                            required
                          />
                          <input
                            className={classes.inputParticipantes}
                            name="complement"
                            type="text"
                            placeholder="Complemento"
                            value={item?.complement}
                            onChange={(e) => changeParticipant(e, index)}
                            required
                          />
                          <input
                            className={classes.inputParticipantes}
                            name={`cep${index}`}
                            type="text"
                            placeholder="Cep"
                            value={item?.cep}
                            onChange={(e) => changeCep(e, index)}
                            minLength="9"
                            maxLength="9"
                            required
                          />
                        </div>
                      ) : (
                        <InputText
                          style={{ borderRadius: 0 }}
                          name="participantes"
                          type="text"
                          placeholder="nome"
                          value={item?.name}
                          onChange={(e) => setParticipantes(e.target.value)}
                          required
                          disabled
                        />
                      )}

                      <Button onClick={() => EditParticipante(item)}>
                        <FaEdit
                          color="dark"
                          size={30}
                          style={{ paddingLeft: 10 }}
                        />
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                <br />
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${classes.button} ${classes.subscribeButton}`}
                    type="submit"
                    style={{ margin: 0 }}
                  >
                    Salvar dados
                  </Button>
                </Grid>
              </form>
              <br />
              <br />
              <form onSubmit={handleSubmitAddGame}>
                <Grid container direction="column" item>
                  <Grid item>
                    <Typography variant="h5">Submissão</Typography>
                  </Grid>
                  <span className={classes.observationNameText}>
                    nome do jogo
                  </span>
                  <Grid item className={classes.gridInputTeam}>
                    <InputText
                      name="gameName"
                      type="text"
                      placeholder="Nome do Jogo"
                      value={gameName}
                      onChange={(e) => setGameName(e.target.value)}
                      required
                      disabled={dados?.game?.name ? true : false}
                    />
                  </Grid>

                  <span className={classes.observationNameText}>descrição</span>
                  <Grid item className={classes.gridInputTeam}>
                    <InputTextLarge
                      name="description"
                      type="text"
                      placeholder="Descrição do jogo"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                      disabled={dados?.game?.description ? true : false}
                    />
                  </Grid>

                  <span className={classes.observationNameText}>feito com</span>
                  <Grid item className={classes.gridInputTeam}>
                    <InputText
                      name="techs"
                      type="text"
                      placeholder="Ex.: Phaser3"
                      value={techs}
                      onChange={(e) => setTechs(e.target.value)}
                      required
                      disabled={dados?.game?.techs ? true : false}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    className={classes.gridInputTeam}
                    justify="center"
                  >
                    <Dropzone
                      onFileUploaded={setThumbnail}
                      thumbnail={dados?.game?.thumbnail}
                    />
                  </Grid>

                  <span
                    className={classes.observationNameText}
                    style={{ padding: "5px 0" }}
                  >
                    arquivos{" "}
                    <AiOutlineQuestionCircle
                      aria-describedby={id}
                      className={classes.tipIcon}
                      onClick={(e) => setAnchorPop(e.currentTarget)}
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorPop}
                      onClose={() => setAnchorPop(null)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div className={classes.contentPop}>
                        um arquivo .zip contendo a aplicação Node do seu game,
                        conforme combinado nos{" "}
                        <Link to="/rules">
                          <span className={classes.linkPop}>
                            termos da competição
                          </span>
                        </Link>
                      </div>
                    </Popover>
                  </span>
                  <Grid item>
                    <input
                      className={classes.uploadButton}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      accept="application/zip"
                    />
                    <label htmlFor={dados?.game ? "" : "raised-button-file"}>
                      {file ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            component="span"
                            className={classes.uploadButton}
                          >
                            {file?.name}
                          </Button>
                          <Typography
                            variant="body1"
                            style={{ paddingTop: 10 }}
                          >
                            Enviando arquivo...
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={uploadProgress}
                            style={{ marginTop: 15 }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            component="span"
                            className={classes.uploadButton}
                          >
                            {dados?.game
                              ? "Jogo já enviado"
                              : "Jogo compactado"}
                          </Button>
                        </div>
                      )}
                    </label>
                  </Grid>
                  <Grid item>
                    <br />
                    <br />
                    {!dados?.game && (
                      <Button
                        variant="contained"
                        className={`${classes.button} ${classes.subscribeButton}`}
                        type="submit"
                        style={{ margin: 0 }}
                      >
                        Enviar jogo
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default MyTeam;
