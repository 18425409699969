import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";
import goUp from "../../utils/goUp";
import VideoCard from "../../components/VideoCard/VideoCard";
import Loader from "react-spinners/PacmanLoader";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
    paddingTop: "150px",
    paddingBottom: "150px",
    minHeight: "100vh",
  },
}));

const Videos = () => {
  const classes = useStyles();

  useEffect(() => {
    goUp();
    setVideos([
      {
        "title":"DINO",
        "url":"https://www.youtube.com/watch?v=b-qfVaV8rVI&list=PLBRp1mw2RYL-po0yrH1U7mvryBU5r966c",
        "description":"Esse é uma playlist de vídeos criando uma imitação mais elaborada do jogo Google Dino.",
        "thumbnail":"https://i.ibb.co/dpyphtB/1.png"
      },
      {
        "title":"PONG",
        "url":"https://www.youtube.com/watch?v=ZlWZ-DZVUTE&list=PLBRp1mw2RYL9pivUXLv1vLRO1I_M42LoY",
        "description":"Esse é uma playlist de vídeos criando uma imitação mais elaborada do jogo Pong.",
        "thumbnail":"https://i.ibb.co/M1sfZK3/pong.jpg"
      },
    ])
  }, []);

  const [ videos, setVideos ] = useState()

  if (!videos) return (
    <>
      <NavBar color="#000" />
      <Grid
      className={classes.background}
      container
      direction="column"
      alignItems="center">
        <Loader color="#C641FF"/>
      </Grid>
    </>
  )

  return (
    <>
      <NavBar color="#000" />
      <div className={classes.background}>
        <Grid
          className={classes.background}
          container
          direction="column"
          alignItems="center">
          
          {videos.map((v, pos)=>(<VideoCard key={pos} {...v}/>))}
          
        </Grid>
      </div>
    </>
  );
};

export default Videos;
