import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";
import goUp from "../../utils/goUp";

import devixLogo from "../../assets/images/devix.svg"
import insideLogo from "../../assets/images/inside.png"
import mitoGamesLogo from "../../assets/images/mitogames.png"

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    alignItems: "center",
    height: "100vh",
  },
  gridItem: {
    margin: "85px 0",
  },
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
  },
  image: {
    width: "100%",
    [theme.breakpoints.up(450)]: {
      padding: "0 20px",
    },
    [theme.breakpoints.down(961)]: {
      paddingBottom: "40px",
    },
  },
  text: {
    fontWeight: "normal",
    fontSize: 18,
    color: "#000",
  },
  linkFont: {
    textDecoration: "none",
    color: "#9e00ff",
    transition: "color 0.25s",

    "&:hover": {
      color: "#7600D8",
    },
  },
}));

const Support = () => {
  const classes = useStyles();

  useEffect(() => {
    goUp();
  }, []);

  return (
    <>
      <NavBar color="#000" />
      <div className={classes.background}>
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item xs={11} sm={9} md={7} lg={6} className={classes.gridItem}>
            <Typography variant="h3">Patrocinadores</Typography>
            <br />
            <br />
            <br />
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={11} sm={9} md={6}>
                <a
                  href="https://devix.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  <img
                    src={devixLogo}
                    className={classes.image}
                    alt="Logo Resultate"
                  />
                </a>
              </Grid>
              <Grid item xs={11} sm={9} md={6}>
                <a
                  href="https://insidetecnologia.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  <img
                    src={insideLogo}
                    className={classes.image}
                    alt="Logo Intelliway"
                  />
                </a>
              </Grid>
              <Grid item xs={11} sm={9} md={6}>
                <a
                  href="https://www.mitogames.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  <img
                    src={mitoGamesLogo}
                    className={classes.image}
                    alt="Logo Intelliway"
                  />
                </a>
              </Grid> 
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Support;
