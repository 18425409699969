import axios from "axios";
import teamAuth from "./teamAuth";
import { apiURL } from "./urls";

const api = axios.create({
  baseURL: apiURL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data === "Token inválido") {
      teamAuth.removeToken();
      window.history.go("/");
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
