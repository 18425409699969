import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";
import goUp from "../../utils/goUp";
import exemploTelaJogo from "../../assets/images/exemploTelaJogo.png";
import exemploPastaJogo from "../../assets/images/exemploPastaJogo.png";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "50px 0",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    margin: "35px 0",
  },
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
  },
  image: {
    border: "solid 1px black",
  },
  text: {
    fontWeight: "normal",
    fontSize: 18,
    color: "#000",
  },
  linkFont: {
    textDecoration: "none",
    color: "#9e00ff",
    transitions: "color 0.25s linear",

    "&:hover": {
      color: "#7600D8",
    },
  },
}));

const Rules = () => {
  const classes = useStyles();

  useEffect(() => {
    goUp();
  }, []);

  return (
    <>
      <NavBar color="#000" />
      <div className={classes.background}>
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item xs={11} sm={9} md={7} lg={6} className={classes.gridItem}>
            <Typography variant="h3">Regras</Typography>
            <br />
            <br />
            <br />
            {/* A data  2023-02-27T03:00:00.000Z quando ajustada para GMT+3 (BRASIL) vira 27/02 00:00*/}
            {Date.now() > new Date("2023-02-27T03:00:00.000Z") && (
                <>
                  <Typography variant="h4">Tema</Typography>
                  <ol className={classes.text}>
                    <li>
                      O tema desta edição do PET Game Jam é:{" "}
                      <a href="https://www.youtube.com/watch?v=fRlI-NsLuUQ"><b>CONFIRA O VÍDEO</b></a>
                    </li>
                  </ol>

                  <br />
                  <br />
                </>
              )}

            <Typography variant="h4">Avisos para as equipes de fora do Espírito Santo</Typography>
            <ol className={classes.text}>
              <li>
                Devido aos custos flutuantes de fretes e outras tarifas, as premiações físicas da competição são {' '}
                restritas apenas aos competidores residentes da Grande Vitória (região metropolitana da capital Vitória-ES).
              </li>
              <li>
                Equipes de fora irão competir em uma '<i>categoria separada</i>', na qual receberão o certificado de participação {' '}
                e, no caso das melhores colocadas, os devidos créditos nas redes sociais ao final da edição.
              </li>
              <li>
                Todas as equipes que <u>efetivamente</u> participarem receberão a certificação ao final.
              </li>
              <li>
                Quaisquer dúvidas podem ser esclarecidas no nosso instagram {' '}
                <a href="https://instagram.com/petengcomp" target="_blank">@petengcomp</a>
              </li>
            </ol>
            <br />
            <br />

            <Typography variant="h4">Gerais</Typography>
            <ol className={classes.text}>
              <li>
                O PET Game Jam é um evento aberto para todos, independente da
                sua idade ou conhecimento em programação;
              </li>
              <li>Crie um jogo sozinho ou em grupo no máximo 3 pessoas;</li>
              <li>
                Crie um jogo durante o período em que o evento estiver acontecendo;
              </li>
              <li>O evento é totalmente gratuito.</li>
            </ol>

            <br />
            <br />
            <Typography variant="h4">Datas</Typography>
            <ol className={classes.text}>
              {
                /* A data  2023-02-20T03:00:00.000Z quando ajustada para GMT+3 (BRASIL) vira 20/02 00:00*/
                Date.now() > new Date("2023-02-20T03:00:00.000Z") ? (
                  <li>As inscrições serão do dia 06/02/2023 até <s>20/02/2023</s><i> (nova data: 27/02/2023)</i>;</li>
                ): (
                  <li>As inscrições serão do dia 06/02/2023 até 20/02/2023;</li>
                )
              }
              
              
              <li>
                A competição irá acontecer entre os dias 27/02/2023 e
                17/03/2023. Esse será o tempo que seu time terá para aprender sobre o desenvolvimento
                de jogos, para enfim criar e enviar um jogo dentro do tema;
              </li>
              <li>
                O tema do jogo que vocês terão que seguir será divulgado aqui na
                página de regras, e também no instagram do PET, no dia 27/02/2023, fiquem atentos!
              </li>
            </ol>

            <br />
            <br />
            <Typography variant="h4">O jogo</Typography>
            <ol className={classes.text}>
              <li>
                Você pode criar seu jogo utilizando qualquer API baseada em
                JavaScript, mas recomendamos bastante que seja feito em Phaser3.
                Também é permitido utilizar qualquer código-fonte que você
                tenha;
              </li>
              <li>
                Você pode utilizar assests de arte/música/áudio de terceiros, ou
                que você já tenha criado;
              </li>
              <li>
                É fortemente recomendado que seja utilizado apenas assests que
                você tenha direito de uso;
              </li>
              <li>
                Se seu jogo estiver parecido com um exemplo de jogo que vem em
                uma ferramenta de desenvolvimento, provavelmente, não terá uma
                boa pontuação. Certifique-se de personalizá-lo completamente e
                criar seu próprio jogo;
              </li>
              <li>
                Não será permitido conteúdo ofensivo a qualquer grupo social,
                incluindo, como exemplo: racismo, sexualidade explícita ou uso
                de drogas;
              </li>
              <li>
                O tema será postado no começo da competição, caso o jogo não
                tenha nenhuma relação com o tema, será desclassificado;
              </li>
              <li>
                Seu jogo deve estar com uma resolução de 800x600 pixels, e seu
                arquivo .html deve estar dessa forma:
              </li>
              <br />
              <img
                src={exemploTelaJogo}
                alt="Exemplo da tela do jogo na web."
                width="100%"
                className={classes.image}
              />
              <b>Obs: </b>É muito importante que a página do seu jogo não possua
              nenhuma borda, padding ou margem.
              <br />
              <br />
              <li>
                Submeter jogos que não são videogames:
                <ul>
                  <li>
                    É permitido submeter card games e jogos de tabuleiro, porém
                    jogá-los é mais complicado (em geral, é necessário mais de
                    uma pessoa para jogá-los). Jogos mais difíceis de jogar
                    costumam ter uma maior dificuldade para obterem boas
                    classificações;
                  </li>
                  <li>
                    O jogo ideal para o PET Game Jam é aquele que pode ser
                    jogado em poucos minutos e sozinho.
                  </li>
                </ul>
              </li>
            </ol>

            <br />
            <br />
            <Typography variant="h4">Submissão</Typography>
            <ol className={classes.text}>
              <li>
                A submissão do seu jogo pode ser feita em qualquer dia e horário
                da semana de competição. <b>NÃO</b> será disponibilizado um
                horário extra a partir que o sistema fechar que será às 23:59 do
                dia final;
              </li>
              <li>
                A submissão deve ser feita enviando apenas um arquivo .zip com
                todas os arquivos do seu jogo, e ele deve estar todo em uma
                página .html. Exemplo do arquivo .zip:
              </li>
              <br />
              <img
                src={exemploPastaJogo}
                alt="Exemplo da pasta do jogo."
                width="100%"
                className={classes.image}
              />
              <li>
                Durante a submissão será exigido uma thumbnail para seu jogo,
                seja criativo. Essa thumbnail deve ter uma resolução de
                aproximadamente 350x235 pixels;
              </li>
              <li>
                Após a submissão não será permitida uma submissão de outro jogo
                ou editar o jogo que foi enviado pela sua equipe. Então fique
                atento para consertar todos os bugs antes do envio. O único caso
                de reenvio de jogo será quando por algum erro no sistema ocorra,
                ou o jogo não funcione na plataforma, nesses casos, tire prints
                e entre em contato com o PET Engenharia de Computação da UFES.
              </li>
            </ol>
            <br />
            <br />
            <Typography variant="h4">Propriedade</Typography>
            <ol className={classes.text}>
              <li>
                Seu jogo pertence a você. Afinal, foi você quem fez. O PET Game
                Jam, seus organizadores e afiliados não reclamam o direito ou
                propriedade do seu jogo.
              </li>
              <li>
                O PET Game Jam solicita o direito de usar seu jogo com propósito
                de publicidade do evento. Se você não deseja que seu jogo seja
                utilizado como publicidade, informe ao PET Engenharia de
                Computação da UFES.
              </li>
            </ol>
            <br />
            <br />
            <Typography variant="h4">Avaliação</Typography>
            <ol className={classes.text}>
              <li>
                O período de votação será de até 1 semana após o fim da competição;
              </li>
              <li>
                Os jogos serão avaliados de duas formas diferentes, sendo que a nota final
                totaliza 10 pontos, sendo 7 pontos dados pela média das
                notas dos juízes da banca avaliadora, e até 3 pontos serão obtidos por enquete no Instagram do
                PET{" "}
                <a
                  href="https://www.instagram.com/petengcomp/?hl=pt-br"
                  target="blank"
                  className={classes.linkFont}
                >
                  @petengcomp
                </a>
                ;
              </li>
              <li>
                A nota dos integrantes do grupo PET acontecerá da seguinte
                forma:
                <ol>
                  <li>
                    Os jogos recebem de 0 a 10 pontos em cada categoria. As
                    categorias são:
                  </li>
                  <ul>
                    <li>
                      <b>Innovation -</b> O inesperado. Os arranjos combinados
                      de foma única, ou algo tão diferente que é notável;
                    </li>
                    <li>
                      <b>Fun -</b> Quão você se divertiu jogando um jogo. Você
                      olhou o relógio e descobriu que já haviam se passado 5
                      horas?
                    </li>
                    <li>
                      <b>Theme -</b> Quão bem o jogo se adequa ao tema. Foi
                      feito algo inesperado ou criativo com o tema?
                    </li>
                    <li>
                      <b>Graphics -</b> Quão bom o jogo parece, ou quão efetivo
                      é o estilo visual. Boa arte, excelente gráficos gerados ou
                      geométricos, boa arte para programadores, etc;
                    </li>
                    <li>
                      <b>Audio -</b> Quão bom ou efetivo é o som do jogo. Uma
                      trilha sonora cativante, efeitos sonoros adequados, boa
                      narração, etc;
                    </li>
                    <li>
                      <b>Humor -</b> Quão engraçado é o jogo. Diálogos bem
                      humorados, sons engraçados, ou é tão ruim que é bom?
                    </li>
                    <li>
                      <b>Mood -</b> história, emoções ou a vibe que você sentiu
                      ao jogar;
                    </li>
                    <li>
                      <b>Overall -</b> Sua opinião geral sobre o jogo, em todos
                      aspectos que são importantes para você.
                    </li>
                  </ul>
                  <li>
                    Após a apuração de votos será feita a média de pontos por
                    categoria de cada petiano, e depois a média de pontos por
                    petiano de cada jogo. A pontuação máxima será de 7 pontos.
                  </li>
                </ol>
              </li>
              <li>
                A nota com a enquete no Instagram acontecerá da seguinte forma:
              </li>
              <ol>
                <li>
                  Ao longo da semana de avaliação, será feita uma enquete perguntando
                  qual foi o melhor jogo do PET Game Jam, e a distribuição de
                  pontos será assim:
                </li>
                <ul>
                  <li>
                    <b>1° lugar mais votado -</b> +3 pontos na pontuação final;
                  </li>
                  <li>
                    <b>2° lugar mais votado -</b> +2,5 pontos na pontuação
                    final;
                  </li>
                  <li>
                    <b>3° lugar mais votado -</b> +2,0 pontos na pontuação
                    final;
                  </li>
                  <li>
                    <b>4° lugar mais votado -</b> +1,5 pontos na pontuação
                    final;
                  </li>
                  <li>
                    <b>5° lugar mais votado -</b> +1,0 pontos na pontuação
                    final;
                  </li>
                  <li>
                    <b>6° lugar mais votado -</b> +0,5 pontos na pontuação
                    final.
                  </li>
                </ul>
              </ol>
            </ol>
            <br />
            <br />
            <Typography variant="h4">Premiação</Typography>
            <ol className={classes.text}>
              <li>
                Cada integrante das equipes melhores colocadas receberá um prêmio!
              </li>
              <ul style={{listStyle:'none'}}>
                <li>
                  <b>1° lugar -</b> Mouse Gamer RedDragon Cobra 12400 DPI + Camiseta do evento
                </li>
                <li>
                  <b>2° lugar -</b> MousePad Gamer 70x35cm + Camiseta do evento
                </li>
                <li>
                  <b>3° lugar -</b> MousePad Gamer 70x35cm
                </li>
              </ul>
              <li>
                A premiação será entrega de maneira física na sala do PET
                Engenharia de Computação que fica na UFES Campus Goiabeiras
                (acesse{" "}
                <a
                  href="https://pet.inf.ufes.br/contato"
                  target="blank"
                  className={classes.linkFont}
                >
                  https://pet.inf.ufes.br/contato
                </a>{" "}
                caso não saiba onde é), todas as medidas de segurança com
                relação à Covid-19 serão tomadas;
              </li>
              <li>
                Será marcado um dia com a equipe para a entrega do prêmio, mas
                caso não seja possível que algum participante vá até a UFES no
                dia marcado, fique tranquilo, nós iremos deixar o prêmio
                guardado e quando possível, será marcado um novo dia para a
                entrega;
              </li>
              <li>
                Quando for buscar o prêmio é importante que os
                participantes do time levem um documento com foto que conste seu
                nome completo, e que esse nome seja igual ao que foi cadastrado
                no site do evento.
              </li>
            </ol>
            <br />
            <br />
            <Typography variant="h4">Dúvidas</Typography>
            <ol className={classes.text}>
              <li>
                Qualquer dúvida, reclamação, sugestão ou elogio pode ser feito
                diretamente com o PET Engenharia de Computação pelos nossos
                canais de comunicação:{" "}
                {/* <a
                  href="https://pet.inf.ufes.br/contato"
                  target="blank"
                  className={classes.linkFont}
                >
                  https://pet.inf.ufes.br/contato
                </a> */}
                <a
                  href="https://www.instagram.com/petengcomp/"
                  target="blank"
                  className={classes.linkFont}
                >
                  https://www.instagram.com/petengcomp
                </a>
                . Estamos trabalhando bastante para fazer do evento o mais
                divertido possível!
              </li>
            </ol>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Rules;
