import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Drawer, Button } from "@material-ui/core";
import { GiHamburgerMenu } from "react-icons/gi";
import NavLinks from "./NavLinks/NavLinks";
import ButtonsLogin from "../ButtonsLogin/ButtonsLogin";

const useStyles = makeStyles((theme) => ({
  appbar: {
    height: 41,
    boxShadow: "none",
    backgroundColor: (props) => props.color,
  },
  toolbar: {
    minHeight: 41,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
    },
  },
  icon: {
    color: (props) => (props.color === "#000" ? "#FFF" : "#000"),
  },
  drawerPaper: {
    backgroundColor: (props) => props.color,
  },
}));

const NavBar = (props) => {
  const classes = useStyles(props);
  const [btnDrawer, setBtnDrawe] = useState(false);

  return (
    <>
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Hidden xsDown>
            <NavLinks color={props.color} />
          </Hidden>
          <Hidden smUp>
            <Button
              aria-label="open drawer"
              edge="start"
              onClick={() => setBtnDrawe(!btnDrawer)}
              className={classes.icon}
            >
              <GiHamburgerMenu size="22px" />
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="top"
        open={btnDrawer}
        onClose={() => setBtnDrawe(!btnDrawer)}
        classes={{ paper: classes.drawerPaper }}
      >
        <NavLinks color={props.color}>
          <ButtonsLogin color={props.color} />
        </NavLinks>
      </Drawer>
    </>
  );
};

export default NavBar;
