import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputText from "../InputText/InputText";
import { MdChevronLeft } from "react-icons/md";
import { validationForgot } from "../../utils/validation";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "2rem",
    cursor: "pointer",
    transition: "color .25s",
    "&:hover": {
      color: "#555",
    },
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 27,
  },
  loader: {
    transform: "translateY(10px)",
    paddingRight: 11,
  },
  iconBack: {
    transform: "translateY(5px)",
  },
  gridItemInput: {
    [theme.breakpoints.up("sm")]: {
      margin: "0px 27px",
    },
  },
  gridItemButton: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 16,
  },
  colorfulText: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 300,
    transition: "color .25s",

    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  button: {
    borderRadius: 53,
    fontSize: 18,
    fontWeight: 500,
    textTransform: "lowercase",
    margin: "0px 10px",
    boxShadow: "none",
    color: "#FFF",
  },
  cancelButton: {
    backgroundColor: theme.palette.disabled.main,
    "&:hover": {
      backgroundColor: theme.palette.disabled.dark,
    },
  },
  formError: {
    padding: "5px 0 0 15px",
    color: "#F44038",
  },
}));

const ForgotPassword = ({ handleClose, setPage }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setPage(1);
    handleClose();
  };

  const handleSubmit = async (values) => {
    const { email } = values;
    setLoading(true);

    try {
      await api.patch("/api/login/forgotPassword", { email });
      setLoading(false);
      toast.success("Email enviado com sucesso!");
      setPage(1);
      handleClose();
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  return (
    <Fade in={true}>
      <div>
        <div className={classes.head}>
          <Typography variant="h2">
            <span className={classes.title} onClick={() => setPage(1)}>
              <MdChevronLeft className={classes.iconBack} />
              Voltar ao login
            </span>
          </Typography>
          {loading && (
            <span className={classes.loader}>
              <CircularProgress size={32} />
            </span>
          )}
        </div>
        <Formik
          validationSchema={validationForgot}
          onSubmit={handleSubmit}
          initialValues={{ email: "" }}
        >
          {({ values, errors, isValid, touched }) => (
            <Form>
              <Grid container direction="column">
                <Grid
                  item
                  style={{ paddingBottom: 22 }}
                  className={classes.gridItemInput}
                >
                  <label htmlFor="email">
                    <Typography variant="body1" style={{ paddingBottom: 25 }}>
                      Digite o email de um dos participantes do time para
                      podermos enviar as novas credenciais.
                    </Typography>
                  </label>
                  <Field name="email">
                    {({ field, meta }) => (
                      <>
                        <InputText
                          type="text"
                          {...field}
                          placeholder="email"
                          id="email"
                          contained
                        />
                        {meta.touched && meta.error && (
                          <div className={classes.formError}>{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item className={classes.gridItemButton}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    type="submit"
                    disabled={!isValid}
                  >
                    enviar
                  </Button>
                  <Button
                    type="reset"
                    onClick={handleCancel}
                    variant="contained"
                    className={`${classes.button} ${classes.cancelButton}`}
                  >
                    cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Fade>
  );
};

export default ForgotPassword;
