import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
  },
  gridInputTeam: {
    margin: "10px 0",
  },
  observationNameText: {
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  observationParticipantText: {
    fontWeight: 400,
    fontSize: 18,
    [theme.breakpoints.up(393)]: {
      lineHeight: 2.6,
    },
  },
  boxParticipant: {
    padding: "10% 7%",
    border: "3px solid #000",
    width: "100%",
    borderRadius: 4,
  },
  gridInputParticipant: {
    margin: "7px 0",
  },
  gridInputAddress: {
    marginTop: 38,
    width: "100%",
  },
  icons: {
    fontSize: 50,
    cursor: "pointer",
    margin: 10,
  },
  labelAgree: {
    fontWeight: 300,
    fontSize: 20,

    [theme.breakpoints.down(475)]: {
      transform: "translateY(8px)",
      textAlign: "left",
    },
  },
  competitionTerms: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  divInputCheckbox: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(475)]: {
      alignItems: "flex-start",
    },
  },
  inputCheckbox: {
    marginRight: 10,
  },
  button: {
    borderRadius: 53,
    width: 150,
    fontSize: 18,
    fontWeight: 100,
    textTransform: "lowercase",
    margin: "10px 10px",
    boxShadow: "none",
    color: "#FFF",
  },
  cancelButton: {
    color: "#797979",
    backgroundColor: theme.palette.disabled.light,
    "&:hover": {
      backgroundColor: theme.palette.disabled.main,
    },
  },
  subscribeButton: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

export default useStyles;
