import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";
import goUp from "../../utils/goUp";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    alignItems: "center",
    minHeight: "100vh",
  },
  gridItem: {
    margin: "85px 0",
  },
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
  },
  image: {
    border: "solid 1px black",
  },
  text: {
    fontWeight: "normal",
    fontSize: 18,
    color: "#000",
  },
  linkFont: {
    textDecoration: "none",
    color: "#9e00ff",
    transition: "color 0.25s",

    "&:hover": {
      color: "#7600D8",
    },
  },
}));

const Tips = () => {
  const classes = useStyles();

  useEffect(() => {
    goUp();
  }, []);

  return (
    <>
      <NavBar color="#000" />
      <div className={classes.background}>
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item xs={11} sm={9} md={7} lg={6} className={classes.gridItem}>
            <Typography variant="h3">Dicas</Typography>
            <br />
            <br />
            <br />
            <Typography variant="h4">Super PETs</Typography>
            <Typography variant="body1">
              Para ajudá-los nessa nova jornada no desenvolvimento de jogos, o
              PET EngComp desenvolveu o Super PETs, um jogo de código aberto no
              github (
              <a
                href="https://github.com/BrunoAngeloti/SuperPets"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkFont}
              >
                repositório
              </a>
              ). Nesse repositório vocês podem ter uma boa base de como
              desenvolver um jogo usando o framework Phaser3.
            </Typography>

            <br />
            <br />
            <Typography variant="h4">Links úteis</Typography>
            <ul className={classes.text}>
              <li>
                <a
                  href="https://photonstorm.github.io/phaser3-docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  Documentação completa Phaser3
                </a>
                ;
              </li>
              <li>
                <a
                  href="https://phaser.io/examples"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  Exemplos Phaser3
                </a>
                , tem muita coisa legal aqui;
              </li>
              <li>
                <a
                  href="https://phaser.io/tutorials/getting-started-phaser3/index"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  Getting Starter Phaser3
                </a>
                ;
              </li>
              <li>
                <a
                  href="https://phaser.io/tutorials/making-your-first-phaser-3-game/part1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  Fazendo seu primeiro jogo em Phaser3
                </a>
                ;
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=frRWKxB9Hm0&list=PLDyH9Tk5ZdFzEu_izyqgPFtHJJXkc79no"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.linkFont}
                >
                  Playlist de vídeos tutoriais ao Phaser3
                </a>
                .
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Tips;
