import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Grid, Typography, Box } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { StarBorderRounded } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import image from "../../assets/images/pixel_background.svg";
import api from "../../services/api";
import goUp from "../../utils/goUp";
import Loader from "../../components/Loader/Loader";
import { gamesURL } from "../../services/urls";

const StyledRating = withStyles({
  iconFilled: {
    color: "#000000",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "bottom",
    backgroundColor: "#FFF",
  },
  container: {
    flexDirection: "row",
    justifyItems: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "100px",
    marginBottom: "100px",
    width: "900px",
  },
  gameImage: {
    borderRadius: "3px",
    width: "100%",
    height: "650px",
  },
  evaluation: {
    flexDirection: "row",
    alignItems: "center",
  },
  gameInfo: {
    paddingTop: 15,
    minWidth: "300px",
    width: "348px",
  },
  gameName: {
    fontSize: "36px",
    fontWeight: "500",
    lineHeight: "47px",
    marginRight: "5px",
  },
  teamMembers: {
    fontSize: "20px",
    lineHeight: "26px",
    color: "#000000",
    fontWeight: "300",
  },
  gameDescription: {
    fontSize: "18px",
    fontWeight: "100",
    lineHeight: "24px",
    color: "#000000",
  },
  teamName: {
    fontSize: "26px",
    lineHeight: "37px",
    color: "#9E00FF",
    fontWeight: "300",
    marginLeft: "15px",
    marginRight: "15px",
  },
  madewith: {
    fontSize: "18px",
    lineHeight: "24px",
    color: "#9E00FF",
    fontWeight: "500",
    marginRight: "15px",
    marginTop: "15px",
  },
  tech: {
    fontSize: "18px",
    lineHeight: "24px",
    color: "#797979",
    fontWeight: "500",
    marginLeft: "15px",
    marginRight: "15px",
  },
  techBox: {
    marginRight: "15px",
    marginTop: "15px",
    padding: "4px",
  },
}));

const GameInfo = (props) => {
  const [participants, setParticipants] = useState([]);
  const classes = useStyles();
  const [game, setGame] = useState();
  const [team, setTeam] = useState();

  useEffect(() => {
    goUp();
  }, []);

  useEffect(() => {
    async function getGame() {
      let id = window.location.pathname;
      id = id.slice(10); // <- retira o /gameinfo/ do id
      const gameInfo = await api.get(`api/game/${id}`);
      setGame(gameInfo.data);
      setTeam(gameInfo.data.team);
    }
    getGame();
  }, []);

  useEffect(() => {
    async function getParticipants() {
      let participantsNamesList = [];
      if (game.team !== undefined) {
        for (let index = 0; index < game.team.participants.length; index++) {
          const participant = await api.get(
            `api/participant/${game.team.participants[index]}`
          );
          participantsNamesList.push(participant.data.participant.name);
        }
        setParticipants(participantsNamesList);
      }
    }
    if (game) {
      getParticipants();
    }
  }, [game]);

  if (!game) {
    return (
      <Grid>
        <NavBar color="#000" />
        <Loader />
      </Grid>
    );
  }

  return (
    <>
      <NavBar color="#000" />
      <Grid
        className={classes.background}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid
          className={classes.container}
          container
          direction="column"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <Grid
            className={classes.gameImage}
            container
            item
            xs={12}
            alignItems="center"
            justify="center"
          >
            <iframe
              src={`${gamesURL}${game?.name
                .toLocaleLowerCase()
                .replace(/ /g, "-")}`}
              frameBorder="0"
              width="810px"
              height="630px"
              seamless="seamless"
              title="game screen"
            ></iframe>
          </Grid>
          <Grid className={classes.gameInfo} item md={12} xs={11}>
            <Grid className={classes.evaluation} container direction="row">
              <Typography className={classes.gameName} variant={"body1"}>
                <b>{game.name}</b>
              </Typography>
              {game ? null : game.ratingCount === 0 ? (
                <StyledRating
                  icon={<StarBorderRounded fontSize="inherit" />}
                  defaultValue={5}
                  precision={0.5}
                  size="large"
                  readOnly
                />
              ) : (
                <StyledRating
                  icon={<StarBorderRounded fontSize="inherit" />}
                  defaultValue={game.rating / game.ratingCount}
                  precision={0.5}
                  size="large"
                  readOnly
                />
              )}
            </Grid>
            <Grid container direction="row" alignItems="baseline">
              <Typography className={classes.teamMembers}>por</Typography>
              <Typography className={classes.teamName}>
                <b>{team?.teamName}</b>
              </Typography>
              <Typography className={classes.teamMembers}>
                (
                {participants.map((participant) => {
                  if (participants.length === 1) {
                    return participant;
                  } else {
                    const index = participants.indexOf(participant);
                    if (index === 0) {
                      return participant;
                    } else if (index === participants.length - 1) {
                      return ` e ${participant}`;
                    } else {
                      return `, ${participant}`;
                    }
                  }
                })}
                )
              </Typography>
            </Grid>
            <Typography className={classes.gameDescription}>
              {" "}
              {game?.description}
            </Typography>
            <Grid container direction="row" alignItems="center">
              <Typography className={classes.madewith}> Feito com</Typography>
              <Box
                className={classes.techBox}
                border={2}
                borderRadius={16}
                borderColor="#797979"
              >
                <Typography className={classes.tech}> {game?.techs}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GameInfo;
